import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/moduleAuth'
import settings from './modules/moduleSettings'

Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    settings,
    auth,
  },
  strict: process.env.NODE_ENV !== 'production'
})

