import {login, logout, signup} from '@/http/auth'

const state = {
  token: localStorage.getItem('user-token') || null,
  activeUser: JSON.parse(localStorage.getItem('user-info')) || null,
}

const getters = {
  isAuthenticated: state => state.token != null,
  token: state => state.token,
  activeUser: state => state.activeUser,
  authLoading: state => state.authLoading,
  isShipper: state => state.token ? state.activeUser.is_shipper : false,
  isCarrier: state => state.token ? state.activeUser.is_carrier : false,
}

const mutations = {
  setToken: (state, payload) => {
    if (payload) {
      localStorage.setItem('user-token', JSON.stringify(payload))
    } else {
      localStorage.removeItem('user-token')
    }
    state.token = payload
  },
  setActiveUser: (state, payload) => {
    if (payload) {
      localStorage.setItem('user-info', JSON.stringify(payload))
    } else {
      localStorage.removeItem('user-info')
    }
    state.activeUser = payload
  },
  setAuthLoading: (state, payload) => {
    state.authLoading = payload
  },
}
const actions = {
  login({commit}, user) {
    return new Promise((resolve, reject) => {
      login(user).then(resp => {
        const token = resp.data.token
        const userInfo = resp.data.user
        commit('setToken', token)
        commit('setActiveUser', userInfo)
        resolve(resp)
      })
        .catch(err => {
          reject(err)
        })
    })
  },
  signup({commit}, data) {
    return new Promise((resolve, reject) => {
      signup(data).then(resp => {
        const token = resp.data.token
        const userInfo = resp.data.user
        commit('setToken', token)
        commit('setActiveUser', userInfo)
        resolve(resp)
      })
        .catch(err => {
          reject(err)
        })
    })
  },
  logout({commit}) {
    if (state.token) {
      logout().then(() => {
        commit('setToken', null)
        commit('setActiveUser', null)
      }).catch(() => {
        commit('setToken', null)
        commit('setActiveUser', null)
      })
    }
  },
}


export default {
  state,
  getters,
  mutations,
  actions
}
