import moment from 'moment'

export function formatDateTime(date, initial_format = "") {
  return moment(date, initial_format).format(process.env.VUE_APP_DATETIME_FORMAT)
}

export function formatDate(date, initial_format = "") {
  return moment(date, initial_format).format(process.env.VUE_APP_DATE_FORMAT)
}

export function formatTime(date, initial_format = "") {
  return moment(date, initial_format).format(process.env.VUE_APP_TIME_FORMAT)
}

export function formatTimeRange(start = null, end = null, initial_format = "") {
  if (start) {
    let out = moment(start, initial_format).format(process.env.VUE_APP_DATETIME_FORMAT)
    if (end) {
      if (isSameDay(start, end, initial_format)) {
        return out + ' - ' + moment(end, initial_format).format(process.env.VUE_APP_TIME_FORMAT)
      } else {
        return out + ' - ' + moment(end, initial_format).format(process.env.VUE_APP_DATETIME_FORMAT)
      }
    }
    return out
  }
}

export function isSameDay(start, end, initial_format = "") {
  if (start && end) {
    return moment(start, initial_format).format(process.env.VUE_APP_DATE_FORMAT) === moment(end, initial_format).format(process.env.VUE_APP_DATE_FORMAT)
  }
  return false
}

export default {
  methods: {
    formatDateTime,
    formatDate,
    formatTime,
    formatTimeRange,
    isSameDay
  }
}
