import axios from 'axios'
import store from '@/store/store'
import router from '@/router'

//const vs = new Vue()
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // You can add your headers here
})

instance.interceptors.request.use(function (config) {
  const token = store.getters.token
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}, function (error) {
  Promise.reject(error)
})

instance.interceptors.response.use((response) =>  {
    if (response.data.code) {
      switch (response.data.code) {
        case 200:
        case 201:
        case 202:
        case 204:
          return response.data
        case 401:
          return new Promise( () =>  {
            store.dispatch('logout')
              .then(() => {
                router.go('/signin')
              })
          })
        case 403:
        case 422:
        case 500:
        default:
          return Promise.reject(response.data)

      }
    } else
    {
      return response.data
    }
  },
  (err) => {
    if (err.response && err.response.data.code) {
      switch (err.response.data.code) {
        case 200:
        case 201:
        case 202:
        case 204:
          return err.response.data
        case 401:
          return new Promise( () =>  {
            store.dispatch('logout')
              .then(() => {
                router.go('/signin')
              })
          })
        case 403:
        case 422:
        case 500:
        default:
          return Promise.reject(err.response.data)
      }
    }
    let errorText = '';
    return new Promise(() => {
      if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
        store.dispatch('AUTH_LOGOUT')
          .then(() => {
            router.go('/signin')
          })
      }

      const isAuthenticated = store.getters.isAuthenticated
      if (isAuthenticated) {
        if (err.response && Object.prototype.hasOwnProperty.call(err.response,'data') && Object.prototype.hasOwnProperty.call(err.response.data,'errors')) {
          for (const key in err.response.data.errors) {
            err.response.data.errors[key].forEach(errorMessage => {
              errorText += `${errorMessage}\n`
            })
          }
          console.error(errorText)
        } else {
          console.error(err.response)
        }
      }

      throw err
    })
  })


export default instance
