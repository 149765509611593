import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import publicRoutes from '@/routes/publicRoutes'
import accountRoutes from '@/routes/accountRoutes'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/signin')
}

const logout = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    store.dispatch('logout').then(() => {
      next('/signin')
    })
    return
  }
  if (to.name === "logout") {
    next('/signin')
  } else {
    next()
  }
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: logout
    },
    {
      path: '/',
      component: () => import('@/layouts/Layout'),
      children: publicRoutes
    },
    {
      path: '/',
      component: () => import('@/layouts/Layout'),
      beforeEnter: ifAuthenticated,
      children: accountRoutes
    },
    {
      path: '*',
      component: () => import('@/views/public/NotFound.vue'),
      meta: {
        pageTitle: '404 - Страница не найдена',
      }
    }
  ]
  // routes: [
  //   {path: '/', redirect: '/orders'},
  //   {
  //     path: '',
  //     component: () => import('./layouts/main/Main.vue'),
  //     //beforeEnter: ifAuthenticated,
  //     children: routes
  //   },

  //   {
  //     path: '/error-404',
  //     name: 'error-404',
  //     component:
  //       () => import('@/views/fullPages/Error404.vue')
  //   },
  //   {
  //     path: '/files/:hash',
  //     name: 'showFile',
  //     beforeEnter: ifAuthenticated,
  //     component:
  //       () => import('@/views/fullPages/ShowFile.vue')
  //   },
  //   // Redirect to 404 page, if no match found
  //   {

  // ]
})

router.afterEach(() => {

})

export default router
