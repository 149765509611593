
import {getSettings} from "@/http/settings"

export const settings = {
  FREE_PERIOD_AFTER_SIGNUP: 2,
}

export default {
  state: {
    settings: []
  },
  getters: {
    FREE_PERIOD_AFTER_SIGNUP: state => state.settings[settings.FREE_PERIOD_AFTER_SIGNUP] ?? 30,
  },
  mutations: {
    saveSettings: (state, data) => {
      state.settings = data
    },
  },
  actions: {
    getSettings:  ({commit}) => {
      return new Promise((resolve) => {
        getSettings().then(resp => {
          let settings = {}
          resp.data.forEach((set) => {
            settings[set.id] = set.value
          })
          commit('saveSettings', settings)
          resolve(resp)
        })
      })
    }
  },
}
