export default [
  {
    path: '/pads',
    name: 'pads',
    component: () => import('@/views/account/Pads.vue'),
    meta: {
      pageTitle: 'площадки',
      infoMessage: 'площадки',
    }
  },
]
