import store from "@/store/store"

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/public/Home.vue'),
    meta: {
      pageTitle: 'умные инструменты обработки заказов  для грузовладельцев и экспедиторов',
    }
  },
  {
    path: '/signin',
    name: 'signIn',
    beforeEnter: ifNotAuthenticated,
    component: () => import('@/views/public/SignIn.vue'),
    meta: {
      pageTitle: 'войти, если вы уже зарегистрированы',
    }
  },
  {
    path: '/signup',
    name: 'signUp',
    beforeEnter: ifNotAuthenticated,
    component: () => import('@/views/public/SignUp.vue'),
    meta: {
      pageTitle: 'регистрация',
    }
  },
]
