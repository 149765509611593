<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    }
  },
  created() {
    this.$store.dispatch('getSettings')
  }
}
</script>
