import Vue from 'vue'
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'

import {
  email,
  max,
  min,
  numeric,
  required,
  confirmed
} from 'vee-validate/dist/rules'
import { setInteractionMode } from 'vee-validate'

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)
extend('confirmed', confirmed)
localize('ru', ru)

localize({
  ru: {
    messages: {
      required: 'поле обязательно',
      email: 'не email'
    },
  }
});

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
setInteractionMode('lazy')
