import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import i18n from '@/i18n'
//import minifyTheme from 'minify-css-string'
import '@/scss/main.scss'

Vue.use(Vuetify)

// import ru from './i18n/vuetify/ru'
//
// export default new Vuetify({
//   lang: {
//     locales: { ru },
//     current: 'ru',
//   },
// })

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  // theme: {
  //   themes: {
  //     light: {
  //       primary: '#FF0000',
  //       secondary: '#CCCCCC',
  //       accent: '#CCCCCC',
  //       error: '#FF0000',
  //       info: '#000000',
  //       success: '#000000',
  //       warning: '#CCCCCC',
  //     },
  //   },
  // },
  theme: {
    disable: true,
    // options: {
    //   minifyTheme,
    //   variations: false
    // },
  },
})
