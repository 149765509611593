import request from '@/http/axios/axios'

export function login(user) {
  return request({
    url: 'login',
    method: 'POST',
    data: user
  })
}

export function signup(data) {
  return request({
    url: 'signup',
    method: 'POST',
    data: data
  })
}


export function logout() {
  return request({
    url: 'logout',
    method: 'POST'
  })
}

